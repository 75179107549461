import { useCallback, useEffect, useState } from 'react';
import styles from './MainHeader.module.scss';
import { NextIconWithNotification } from '../NextImage/NextIconWithNotification';
import { Notification } from '../Notifications';
import { getNotifications, setReadNotifications } from '../../api';
import cx from 'classnames';
import { NextLink } from '../NextLink';
import { NotificationItem } from '../../types';
import { selectCurrentUser, useAppSelector } from '../../store';


export default ({notification}: {notification: number}) => {
  const currentUser = useAppSelector(selectCurrentUser);
  const [isOpened, setIsOpened] = useState(false)
  const [data, setData] = useState<NotificationItem[]>([])

  const fetchData = useCallback(() => {
    getNotifications({page: 1, limit: 5})
      .then(response => {
        setData(response.data)
      })
  }, [])

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    if(isOpened)
      fetchData()
  }, [isOpened])

  const onReadAll = useCallback((e) => {
    e.stopPropagation()
    setReadNotifications('all')
      .then(() => {
        setData(d => d.map((i) => ({ ...i, seen: true })))
      })
  }, [])

  return (
    <div className={styles.item} onClick={() => setIsOpened(!isOpened)}>
      <NextIconWithNotification
        alt="Notifications"
        src="/icons/design/NotificationBell.svg"
        notification={notification}
      />

      {isOpened && (
        <div className={styles.modalContainer}>
          <div className={styles.cower} onClick={() => setIsOpened(false)} />
          <div className={styles.notificationsOverlay}>
            <div className={cx(styles.header, styles.borderBottom)}>
              <span className={styles.title}>Notifications</span>
              <span className={styles.link} onClick={onReadAll}>Read all</span>
            </div>

            <div className={styles.scrollContainer}>
              {data.length > 0
                ? data.map((data) => (
                  <Notification
                    key={data.id} data={data}
                    updateNote={(note) => {
                      setData(d => (d.map((i) => {
                          if(data.id===i.id)
                            i = note
                          return i
                        })
                      ))
                    }}
                    username={currentUser?.username}
                  />
                ))
                : (
                  <div className={styles.header}>
                    <span>You don’t have notifications yet</span>
                  </div>
                )
              }
            </div>


            <div className={styles.footer}>
              <NextLink href={`/notifications`}>
                <span className={styles.link}>View all notifications</span>
              </NextLink>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
