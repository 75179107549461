import Image from 'next/legacy/image';
import { APPSTORE_APP_LINK, GOOGLE_PLAY_APP_LINK } from 'consts';
import { Button } from 'components/Button';
import { NextLink } from 'components/NextLink';
import { NextBackgroundImage } from 'components/NextImage';

import cx from 'classnames';
import styles from './AppInvitation.module.scss';

export type AppInvitationProps = {
  isMobile?: boolean;
};

export const AppInvitation = ({ isMobile }: AppInvitationProps) => {
  const onRedirect = () => {
    window.location.assign('https://onelink.to/sendy');
  };

  return (
    <div className={cx(styles.container, { [styles.mobile]: isMobile })}>
      {!isMobile && <NextBackgroundImage objectFit="contain" src="/iPhone 14 Pro.png" customClass={styles.iphone} />}
      <div className={styles.header}>
        <h2>
          <span>SENDY</span> is better <br /> on the app
        </h2>
        {isMobile ? (
          <>
            <Button action={onRedirect} caption="Download the app" />
            <NextBackgroundImage src="/iPhone 14 Pro.png" customClass={styles.iphone} />
          </>
        ) : (
          <div className={styles.storeButtons}>
            <NextLink href={APPSTORE_APP_LINK} newTab={true}>
              <Image
                alt="App Store"
                src="/App_Store_Badge.svg"
                width={162}
                height={48}
                layout="fixed"
                objectFit="cover"
              />
            </NextLink>
            <NextLink href={GOOGLE_PLAY_APP_LINK} newTab={true}>
              <Image
                alt="Google Pay"
                src="/Google_Play_Store.svg"
                width={162}
                height={48}
                layout="fixed"
                objectFit="cover"
              />
            </NextLink>
          </div>
        )}
      </div>
    </div>
  );
};
