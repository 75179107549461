import { Fragment } from 'react';
import Head from 'next/head';
import { OgData } from 'types';

export type HeadComponentProps = {
  headTitle: string;
  ldJsonData?: string;
  ogData?: OgData[];
};

export const HeadComponent = ({ headTitle, ogData, ldJsonData }: HeadComponentProps) => {
  return (
    <Head>
      <title>{headTitle}</title>
      <meta name="viewport" content="initial-scale=1.0, maximum-scale=1, width=device-width, height=device-height" />
      <meta httpEquiv="content-type" content="text/html; charset=UTF-8" />
      {ldJsonData && (
        <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: ldJsonData }}></script>
      )}
      {!!ogData?.length &&
        ogData
          .filter(data => !!data.content)
          .map((data, index) => (
            <Fragment key={index}>
              <meta property={data.prop} content={data.content} />
              {data.prop === 'description' ? <meta name={data.prop} content={data.content} /> : null}
            </Fragment>
          ))}
    </Head>
  );
};
