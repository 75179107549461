import Image from 'next/legacy/image';
import { USER_PHOTO_PLACEHOLDER_LINK } from '../../consts';
import styles from './Notifications.module.scss';
import moment from 'moment';
import cx from 'classnames';
import { useCallback, useMemo } from 'react';
import { setReadNotifications } from '../../api';
import { NotificationItem } from '../../types';
import { NextLink } from '../NextLink';
import { getNotificationLink } from './notificationHandler';
import { formatDateAgo } from '../../utils/formatters';
import { goTo } from '../../utils/services';
import { useRouter } from 'next/router';


export type NotificationItemProps = {
  data: NotificationItem;
  updateNote: (data: NotificationItem) => void;
  username: string | undefined;
};

export const Notification = ({data, updateNote, username} : NotificationItemProps) => {
  const router = useRouter()

  const onRead = useCallback((seen: boolean) => {
    updateNote({ ...data, seen })
    setReadNotifications(data.id, {seen})
      .catch((err) => {
        console.log(err.response)
      })
  }, [])

  const link = useMemo(() => getNotificationLink({data: {...data.meta, type: data.type}}, username), [data])

  return (
    <div onClick={() => goTo(router, link)}>
      <div className={cx(styles.container, styles[`background-${data.seen}`])}>
        <div className={styles.image}>
          <Image
            alt="avatar"
            width={48} height={48}
            className={styles.image}
            src={
              data.user_photo ? (data.user_photo_250x250 || data.user_photo)
                : data.item_cover_image_urls ? data.item_cover_image_urls['400x400']
                : data.item_cover_image ? data.item_cover_image
                  : USER_PHOTO_PLACEHOLDER_LINK
            }
          />
        </div>
        <div className={styles.textContainer}>
          <div className={styles.notificationContent} dangerouslySetInnerHTML={{ __html: data.body }} />
          <div className={styles.readRow}>
            <span className={styles.time}>{formatDateAgo(data.created_at)}</span>
            <div className={styles.dot}/>
            <span
              className={styles.read}
              onClick={(e) => {
                e.stopPropagation()
                onRead(!data.seen)
              }}
            >{data.seen ? 'Unread' : 'Read'}</span>
          </div>
        </div>
      </div>
    </div>
  )
}
