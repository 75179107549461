export const socialFields = [
  {
    icon: '/icons/brands/InstagramLogo.svg',
    src: '/',
  },
  {
    icon: '/icons/brands/TwitterLogo.svg',
    src: '/',
  },
  {
    icon: '/icons/brands/InstagramLogo.svg',
    src: '/',
  },
  {
    icon: '/icons/brands/WhatsappLogo.svg',
    src: '/',
  },
  {
    icon: '/icons/brands/YoutubeLogo.svg',
    src: '/',
  },
];

export const infoFields = [
  {
    title: `© ${(new Date()).getFullYear()} SENDY, Inc.`,
    link: '/',
  },
  {
    title: 'Privacy Policy',
    link: '/privacy-policy',
  },
  {
    title: 'Terms of Service',
    link: '/terms-of-use',
  },
];
