import { Fragment } from 'react';

import styles from './SearchAutocompleteResult.module.scss';
import cx from 'classnames';

export type SearchAutocompleteResultProps = {
  result?: Array<ResultItem>;
  onClickItem?: (item: ResultItem) => void;
};

export type ResultItem = {
  id: string;
  title: string;
  description?: string;
  overlapped?: boolean;
  lineDown?: boolean;
  lineUp?: boolean;
  meta?: object;
};

export const SearchAutocompleteResult =({
  result = [],
  onClickItem = () => {}
}: SearchAutocompleteResultProps) => {
  const handleClickItem = (item: ResultItem) => {
    onClickItem(item);
  };

  return !result.length ? null : (
    <div className={styles.autocomplete}>
      <ul className={styles.resultsList}>
        {
          result.map((item: ResultItem) => (
            <Fragment key={item.id}>
              {
                item.lineUp ? (
                  <li className={cx(styles.resultsItem, styles.line)}>
                  </li>
                ) : null
              }
              <li className={cx(styles.resultsItem, {
                [styles.withDesc]: item.description
              })} onClick={() => handleClickItem(item)}>
                {
                  item.description ? (
                    <>
                      <span className={cx(styles.title, {
                        [styles.resultOverlap]: item.overlapped,
                      })}>{item.title}</span>
                      <span className={styles.description}>{item.description}</span>
                    </>
                  ) : (<>{item.title}</>)
                }
              </li>
              {
                item.lineDown ? (
                  <li className={cx(styles.resultsItem, styles.line)}>
                  </li>
                ) : null
              }
            </Fragment>
          ))
        }
      </ul>
    </div>
  )
};
