import { useState } from 'react';
import Image from 'next/legacy/image';
import Link from 'next/link';

import styles from './SubHeader.module.scss';
import cx from 'classnames';

export type MenuItemProps = {
  value: string;
  src: string;
};

export type MenuProps = {
  menuFields: Array<MenuItemProps>;
  isOpened: boolean;
};

export const SubHeader = ({ menuFields, isOpened }: MenuProps) => {
  const [activeItem, setActiveItem] = useState<number | null>(null);

  return (
    <nav className={cx(styles.menu, { [styles.open]: isOpened, [styles.close]: !isOpened })}>
      <ul>
        {menuFields &&
          menuFields.map((element, index) => {
            const { value, src } = element;
            return (
              <li
                key={index}
                onClick={() => setActiveItem(index)}
                className={cx({ [styles.active]: activeItem === index })}
              >
                <Link href={src} legacyBehavior={true}>
                  <a>{value}</a>
                </Link>
                <div className={styles.arrow}>
                  <Image src="/icons/arrows/ArrowRight.svg" height={24} width={24} alt="Logo" />
                </div>
              </li>
            );
          })}
      </ul>
    </nav>
  );
};
