import Link from 'next/link';
import { SocialMedia } from './SocialMedia';

import { socialFields, infoFields } from './socialFilds';

import styles from './Footer.module.scss';

export const Footer = () => {
  return (
    <footer className={styles.footer}>
      {/*<div className={styles.wrapper}>*/}
      {/*  /!*<div className={styles.content}>*!/*/}
      {/*  /!*  <div className={styles.tab}>*!/*/}
      {/*  /!*    <div className={styles.title}>Categories</div>*!/*/}
      {/*  /!*    <div className={styles.tabItems}>*!/*/}
      {/*  /!*      <span>Ski</span>*!/*/}
      {/*  /!*      <span>Ski</span>*!/*/}
      {/*  /!*      <span>Ski</span>*!/*/}
      {/*  /!*      <span>Ski</span>*!/*/}
      {/*  /!*    </div>*!/*/}
      {/*  /!*  </div>*!/*/}
      {/*  /!*  <div className={styles.tab}>*!/*/}
      {/*  /!*    <div className={styles.title}>Popular Brands</div>*!/*/}
      {/*  /!*    <div className={styles.tabItems}>*!/*/}
      {/*  /!*      <span>Ski</span>*!/*/}
      {/*  /!*      <span>Ski</span>*!/*/}
      {/*  /!*      <span>Ski</span>*!/*/}
      {/*  /!*      <span>Ski</span>*!/*/}
      {/*  /!*    </div>*!/*/}
      {/*  /!*  </div>*!/*/}
      {/*  /!*  <div className={styles.tab}>*!/*/}
      {/*  /!*    <div className={styles.title}>About SENDY</div>*!/*/}
      {/*  /!*    <div className={styles.tabItems}>*!/*/}
      {/*  /!*      <span>Ski</span>*!/*/}
      {/*  /!*      <span>Ski</span>*!/*/}
      {/*  /!*      <span>Ski</span>*!/*/}
      {/*  /!*      <span>Ski</span>*!/*/}
      {/*  /!*    </div>*!/*/}
      {/*  /!*  </div>*!/*/}
      {/*  /!*</div>*!/*/}
      {/*  <SocialMedia socialFields={socialFields} />*/}
      {/*</div>*/}
      <ul className={styles.info}>
        {infoFields.map((infoField, index) => {
          return (
            <li key={index}>
              <Link href={infoField.link} legacyBehavior={true}>
                <a>{infoField.title}</a>
              </Link>
            </li>
          );
        })}
      </ul>
    </footer>
  );
};
