import { memo, useEffect, useRef, useState } from 'react';
import { ChangeEvent, KeyboardEvent } from 'react';
import Image from 'next/legacy/image';
import { Preloader } from 'components/Preloader';

import styles from './SearchInput.module.scss';
import cx from 'classnames';

export type SearchInputProps = {
  className?: string;
  customInputStyle?: string;
  id?: string;
  isDropDownSearch?: boolean;
  isHide?: boolean;
  isHeaderSearch?: boolean;
  isFocused?: boolean;
  isMobileSearch?: boolean;
  onEnter?: (query: string) => void;
  onFocus?: (isFocused: boolean) => void;
  onSearch: (value: string) => void;
  placeholder?: string;
  value?: string;
  isWide?: boolean;
  isLoading?: boolean;
};

const typingDelay = 500;

export const SearchInput = memo(function WrappedSearchInput({
  className,
  id,
  isDropDownSearch,
  isHeaderSearch,
  isHide,
  customInputStyle,
  onEnter,
  onFocus = () => {},
  isFocused,
  isMobileSearch,
  onSearch,
  placeholder,
  value,
  isWide,
  isLoading = false,
}: SearchInputProps) {
  const [currentValue, setCurrentValue] = useState<string>(value ? value : '');
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (currentValue && !value) {
      handleClear();
    } else {
      setCurrentValue(value || '');
    }
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCurrentValue(e.target.value);
    setTimeout(() => {
      setCurrentValue((prev) => {
        if (prev === e.target.value) {
          onSearch(prev);
        }
        return prev;
      });
    }, typingDelay);
  };

  const handleClear = () => {
    onSearch('');
    setCurrentValue('');

    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      onEnter && onEnter(inputRef.current?.value || '');
    }
  };

  const handleFocus = () => {
    onFocus(true);
    inputRef.current?.focus();
  };

  return (
    <div
      className={cx(
        styles.wrapper,
        {
          [styles.hide]: isHide,
          [styles.focusedWrapper]: isFocused && isMobileSearch,
        },
        className,
      )}
    >
      <div className={cx(styles.img, { [styles.focusedImg]: isFocused && !isDropDownSearch })} onClick={handleFocus}>
        {isLoading ? <Preloader /> : (
          <Image
            alt="Image"
            src={
              isDropDownSearch && isMobileSearch && isHeaderSearch
                ? '/icons/MagnifyingGlass.svg'
                : '/icons/system/MagnifyingGlassSoft.svg'
            }
            width={24}
            height={24}
          />
        )}
      </div>
      <input
        id={id}
        ref={inputRef}
        type="text"
        name="search"
        className={cx(styles.search, customInputStyle, {[styles.wide]: isWide})}
        placeholder={placeholder ? placeholder : 'Search...'}
        onChange={onChange}
        onFocus={handleFocus}
        onBlur={() => onFocus(false)}
        onKeyDown={handleKeyDown}
        value={currentValue}
        enterKeyHint="search"
      />
      <div
        className={cx(styles.clearImage, {
          [styles.focusedClearImg]: isFocused && !!currentValue,
          [styles.dropDownModeIcon]: isDropDownSearch,
        })}
        onClick={handleClear}
      >
        <Image alt="Image" src="/icons/development/XGrey.svg" width="18" height="18" />
      </div>
    </div>
  );
});
