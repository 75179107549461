import cx from 'classnames';
import styles from './Burger.module.scss';
import {NextIcon} from "../NextImage";

export type BurgerType = {
  changeOpened?: () => void;
  className?: string;
};

export const Burger = ({ changeOpened = () => null, className }: BurgerType) => {
  return (
    <div onClick={() => changeOpened()} className={cx(styles.burger, className)}>
      <NextIcon
        customClass={styles.dropdownIcon}
        src="/icons/DotsThree.svg"
        size={32}
        alt="close"
      />
    </div>
  );
};
