import { NextIcon } from 'components/NextImage';
import { MenuItem } from 'components/MenuItem';

import cx from 'classnames';
import styles from './SideMenu.module.scss';

export type SideMenuItem = {
  alt: string;
  className?: string;
  iconSrc?: string;
  onClick?: () => void;
  title: string;
  divider?: boolean;
  hideOnDesktop?: boolean;
  notification?: number;
};

export type SideMenuProps = {
  active?: boolean;
  after?: JSX.Element;
  before?: JSX.Element;
  customClass?: string;
  headerContent?: JSX.Element;
  items: Array<SideMenuItem>;
  setActive?: (active: boolean) => void;
};

export const SideMenu = ({
  active,
  after,
  before,
  customClass,
  headerContent,
  items = [],
  setActive,
}: SideMenuProps) => {
  const closeMenu = () => setActive && setActive(false);

  const renderMenuItem = (itemProps: SideMenuItem, index: number) => {
    if (itemProps.iconSrc) {
      return (
        <MenuItem
          className={cx({ [styles.hideOnDesktop]: itemProps.hideOnDesktop }, styles.menuItem)}
          key={index}
          {...itemProps}
        />
      );
    }
    if (itemProps.title) {
      return <p className={styles.title}>{itemProps.title}</p>;
    }
    return <div className={styles.divider} />;
  };

  return (
    <>
      <div className={cx(styles.container, customClass, { [styles.active]: active })}>
        <div className={styles.header}>
          {headerContent && headerContent}
          <NextIcon src="/icons/XWhite.svg" customClass={styles.closeIcon} onClick={closeMenu} />
        </div>
        <div className={styles.scrollableContent}>
          {before && before}
          {items.map(renderMenuItem)}
          {after && after}
        </div>
      </div>
      <div className={cx(styles.cover, { [styles.active]: active })} onClick={closeMenu} />
    </>
  );
};
