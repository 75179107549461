import { useEffect, useState } from 'react';
import { CurrentUser, DropdownOption } from 'types';
import { debounce } from 'utils/services';
import { Dropdown } from 'components/Dropdown';
import { SearchInput } from 'components/SearchInput';
import { ResultItem, SearchAutocompleteResult } from 'components/SearchAutocompleteResult';

import styles from './DropDownSearch.module.scss';
import cx from 'classnames';

export type DropDownSearchProps = {
  id?: string;
  headerSearch?: boolean;
  onChangeDropDownValue?: (value: number) => void;
  onChangeQuery?: (q: string) => void;
  onInputQuery?: (q: string) => void;
  onClickResultItem?: (item: ResultItem) => void;
  onEnterSearch?: (q: string) => void;
  placeholder?: string;
  searchOptions?: DropdownOption[];
  showResult?: boolean;
  result?: Array<ResultItem>;
  user?: CurrentUser;
  value: number;
  debounceTimeout?: number;
  resultLoading?: boolean;
};

const findOptionTitle = (options: DropdownOption[] = [], value: number) => {
  const option = options.find((o) => o.id === value || o.value === `${value}`);
  return option ? option.title || option.label : option;
};

export const DropDownSearch = ({
  id,
  headerSearch,
  onChangeDropDownValue,
  onChangeQuery,
  onInputQuery,
  onClickResultItem,
  onEnterSearch,
  searchOptions,
  showResult = false,
  placeholder,
  result = [],
  value,
  debounceTimeout = 400,
  resultLoading = false,
}: DropDownSearchProps) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [isMobileSearch, setIsMobileSearch] = useState<boolean>(false);
  const [activeOption, setActiveOption] = useState(findOptionTitle(searchOptions, value));

  useEffect(() => {
    handleWidthChange();
    window.addEventListener('resize', handleWidthChange);

    return () => window.removeEventListener('resize', handleWidthChange);
  }, []);

  const handleWidthChange = () => {
    setIsMobileSearch(window.innerWidth <= 768);
  };

  const handleChangeDropDownValue = (fieldName: any, value: any) => {
    setActiveOption(findOptionTitle(searchOptions, value));
    onChangeDropDownValue && onChangeDropDownValue(value);
  };

  const handleFocus = (focused: boolean) => {
    focused ? setIsFocused(focused) : setTimeout(() => setIsFocused(focused), 500);
  };

  const debauncer = debounce((q: string) => {
    onChangeQuery && onChangeQuery(q);
  }, debounceTimeout);

  const handleSearch = (q) => {
    onInputQuery && onInputQuery(q);
    debauncer(q);
  };

  const handleClickResultItem = (item: ResultItem) => {
    onClickResultItem && onClickResultItem(item);
  };

  const handleEnterSearch = (q: string) => {
    onEnterSearch && onEnterSearch(q);
  };

  return (
    <div
      className={cx(styles.wrapper, {
        [styles.focusedWrapper]: isFocused && isMobileSearch,
        [styles.commonSearchWrapper]: !headerSearch,
      })}
    >
      <div className={styles.searchInput}>
        <SearchInput
          customInputStyle={!isFocused && isMobileSearch && headerSearch ? styles.mobileCutInput : styles.input}
          id={id}
          isDropDownSearch={true}
          isFocused={isFocused}
          isHeaderSearch={headerSearch}
          isMobileSearch={isMobileSearch}
          onSearch={handleSearch}
          onFocus={handleFocus}
          onEnter={handleEnterSearch}
          placeholder={placeholder ? placeholder : activeOption ? `Search by ${activeOption}` : activeOption}
          isLoading={resultLoading}
        />
      </div>
      {searchOptions ? (
        <div
          className={cx(styles.dropdown, {
            [styles.focusedDropdown]: isFocused,
          })}
        >
          <Dropdown
            customClass={styles.dropdownElement}
            isThin={true}
            onChange={handleChangeDropDownValue}
            options={searchOptions}
            value={value}
          />
        </div>
      ) : (
        <div className={styles.optionsIndent} />
      )}
      {showResult && isFocused && <SearchAutocompleteResult result={result} onClickItem={handleClickResultItem} />}
    </div>
  );
};
