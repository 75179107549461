import React, { useEffect, useState } from 'react';
import { BadgeSectionTypes, CurrentUser, MenuResponse, OgData, HiddenComponentProps } from 'types';
import { Footer } from 'components/Footer';
import { BaseSubHeader } from 'components/SubHeader';
import { HeaderSearch } from 'components/SearchComponents/HeaderSearch';
import { HeadComponent } from 'components/HeadComponent';
import { AdvertisementOptionalModal } from 'components/AdvertisementOptionalModal';
import { MainHeader } from 'components/MainHeader';
import { SeoHiddenContent } from 'components/SeoHiddenContent';
import { getCommonOgData, getPageDescription } from 'utils/seoFormatters';
// import { MobileAppBanner } from 'components/MobileAppBanner';

import styles from './CustomPageWrapper.module.scss';
import { removeLogInDataFromBrowser } from '../../utils/localStoreTools';
import { useRouter } from 'next/router';
import { NextLink } from '../../components/NextLink';
import { NextIcon } from '../../components/NextImage';
import cx from 'classnames';

export type CustomerPageWrapperProps = {
  badge?: BadgeSectionTypes;
  children?: React.ReactElement;
  filters?: Record<string, string | number | boolean>;
  headTitle?: string;
  hiddenComponentProps?: HiddenComponentProps;
  ldJsonData?: string;
  menuData?: MenuResponse;
  ogData?: OgData[];
  user?: CurrentUser;
  withFooter?: boolean;
  // seo props
  seoPageName?: string;
  seoPageData?: object;
};


export const PurchaseWrapper = ({
                                      badge,
                                      children,
                                      filters,
                                      headTitle = 'SENDY',
                                      hiddenComponentProps,
                                      ldJsonData,
                                      menuData = {} as MenuResponse,
                                      ogData,
                                      user,
                                      withFooter = true,
                                      seoPageName = 'default',
                                      seoPageData = {},
                                      appliedFilters = {},
                                    }: CustomerPageWrapperProps) => {
  const router = useRouter();
  const [isOpened, setOpened] = useState(false);

  useEffect(() => {
    if(!!user?.registration_step){
      removeLogInDataFromBrowser();
      router.reload();
    }

    if (isOpened) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpened]);

  const toggleOpened = () => {
    setOpened((isOpened) => !isOpened);
  };

  if (!ogData) {
    ogData = getCommonOgData({
      description: getPageDescription(seoPageName as string, seoPageData),
    });
  }

  return (
    <React.Fragment>
      <HeadComponent headTitle={headTitle} ldJsonData={ldJsonData} ogData={ogData} />
      <div className={styles.purchaseHeader}>
        <NextIcon src="/icons/Secure.svg" width={156} height={32} alt="Logo" />
        <NextLink href="/" className={cx(styles.logoLink, styles.desktopOnly)} useDirectLink={router.route === '/'}>
          <NextIcon src="/icons/logo.svg" width={156} alt="Logo" customClass={styles.logo} />
        </NextLink>
        <NextLink className={styles.desktopOnly} href={'/c/contact'}>
          <p className={styles.desktopOnly}>Need Help? Contact Us!</p>
        </NextLink>
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.contentContainer}>{children && children}</div>
      </div>
    </React.Fragment>
  );
};
