import React, { useEffect, useState } from 'react';
import { ModalProps } from 'types';
import { checkIfShouldShowModal } from 'utils/localStoreTools';
import { Modal } from 'components/Modal';
import { AppInvitation } from 'components/AppInvitation';

export const AdvertisementOptionalModal = () => {
  const [modalProps, setModalProps] = useState<ModalProps | null>(null);

  useEffect(() => {
    const isMobile = document.body.getBoundingClientRect().width < 1000;

    if (checkIfShouldShowModal('app-invitation')) {
      setTimeout(
        () =>
          setModalProps({
            background: isMobile ? 'url(/adv_back_mob.png)' : 'url(/adb_back_desk.png)',
            child: <AppInvitation isMobile={isMobile} />,
            cutContent: true,
            fitChild: true,
            onCoverClick: () => setModalProps(null),
            withCrossIcon: true,
          }),
        60000,
      );
    }
  }, []);

  return <div>{modalProps && <Modal {...modalProps} />}</div>;
};
