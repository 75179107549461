
export const getNotificationLink = ({data}, username) => {
  if (data.type==='new_message')
   return `messages/rooms/${data.room_id}`

  if (
    data.type==='item' || data.type==='gear_is_live' || data.type==='got_feed_post' || data.type==='gear_was_shared'
    || data.type==='got_new_like' || data.type==='got_new_comment' || data.type==='mentions'
    || data.type==='new_comment_received' || data.type==='mentioned_in_comment'
  )
    return `gear/${data.item_slug}`


  if (data.type==='item_management') {
    const step = data.subtype==='price' ? 4 : 1

    return `item-management/${data.item_id}?step=${step}`
  }


  if (data.type==='got_followed' || data.type==='new_follower')
    return `users/${data.username}`

  if (data.type==='new_review' || data.type==='got_new_review')
    return `users/${username}?tab=about`

  if (
    data.type==='offer' || data.type==='got_new_offer' ||
    data.type==='seller_offer' || data.type==='seller_new_offer' || data.type==='seller_accept_offer_failed'
  )
    return `/my-offers`

  if (data.type==='buyer_offer' || data.type==='buyer_new_offer' || data.type==='buyer_offer_declined')
    return `/my-offers?tab=purchase`

  if (
    data.type==='order' || data.type?.search('buyer_order')===0 || data.type?.search('seller_order')===0
    || data.type==='buyer_new_order' || data.type==='seller_new_order' || data.type==='order_ship_reminder'
    || data.type==='order_status_updated' || data.type==='return_item' || data.type==='order_completed'
    || data.type==='order_canceled' || data.type==='got_new_sale_order' || data.type==='got_new_rent_order'
    || data.type==='rent_overdue' || data.type==='rent_cancelled_due_overdue'
  )
    return `/my-orders/details/${data.order_id}`

  if (data.type==='payout_requested' || data.type==='payout_completed')
    return `/transactions`

  return ''
}
