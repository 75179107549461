import { NextBackgroundImage } from '../NextImage';
import { HiddenComponentProps } from 'types';

import styles from './SeoHiddenContent.module.scss';

export const SeoHiddenContent = ({ description, image, title }: HiddenComponentProps) => {

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>{title}</h1>
      {image && <NextBackgroundImage src={image} customClass={styles.mainImage} />}
      {description && <p className={styles.description}>{description}</p>}
    </div>
  );
};
